import { createConnectTransport } from "@bufbuild/connect-web";
import { QuickFeedService } from "../../proto/qf/quickfeed_connectweb";
import { StreamService } from "../streamService";
import { createResponseClient } from "../client";
export class ApiClient {
    client;
    init(errorHandler) {
        this.client = createResponseClient(QuickFeedService, createConnectTransport({
            baseUrl: `https://${window.location.host}`
        }), errorHandler);
    }
}
export const api = new ApiClient();
export const streamService = new StreamService();
