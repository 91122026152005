import { CourseRequest, CourseSubmissions, EnrollmentRequest, GroupRequest, Organization, RebuildRequest, Repositories, RepositoryRequest, ReviewRequest, SubmissionRequest, UpdateSubmissionRequest, UpdateSubmissionsRequest, Void } from "./requests_pb.js";
import { Assignments, Course, Courses, Enrollment, Enrollments, GradingBenchmark, GradingCriterion, Group, Groups, Review, Submission, Submissions, User, Users } from "./types_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
export const QuickFeedService = {
    typeName: "qf.QuickFeedService",
    methods: {
        getUser: {
            name: "GetUser",
            I: Void,
            O: User,
            kind: MethodKind.Unary,
        },
        getUsers: {
            name: "GetUsers",
            I: Void,
            O: Users,
            kind: MethodKind.Unary,
        },
        updateUser: {
            name: "UpdateUser",
            I: User,
            O: Void,
            kind: MethodKind.Unary,
        },
        getGroup: {
            name: "GetGroup",
            I: GroupRequest,
            O: Group,
            kind: MethodKind.Unary,
        },
        getGroupsByCourse: {
            name: "GetGroupsByCourse",
            I: CourseRequest,
            O: Groups,
            kind: MethodKind.Unary,
        },
        createGroup: {
            name: "CreateGroup",
            I: Group,
            O: Group,
            kind: MethodKind.Unary,
        },
        updateGroup: {
            name: "UpdateGroup",
            I: Group,
            O: Group,
            kind: MethodKind.Unary,
        },
        deleteGroup: {
            name: "DeleteGroup",
            I: GroupRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        getCourse: {
            name: "GetCourse",
            I: CourseRequest,
            O: Course,
            kind: MethodKind.Unary,
        },
        getCourses: {
            name: "GetCourses",
            I: Void,
            O: Courses,
            kind: MethodKind.Unary,
        },
        updateCourse: {
            name: "UpdateCourse",
            I: Course,
            O: Void,
            kind: MethodKind.Unary,
        },
        updateCourseVisibility: {
            name: "UpdateCourseVisibility",
            I: Enrollment,
            O: Void,
            kind: MethodKind.Unary,
        },
        getAssignments: {
            name: "GetAssignments",
            I: CourseRequest,
            O: Assignments,
            kind: MethodKind.Unary,
        },
        updateAssignments: {
            name: "UpdateAssignments",
            I: CourseRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        getEnrollments: {
            name: "GetEnrollments",
            I: EnrollmentRequest,
            O: Enrollments,
            kind: MethodKind.Unary,
        },
        createEnrollment: {
            name: "CreateEnrollment",
            I: Enrollment,
            O: Void,
            kind: MethodKind.Unary,
        },
        updateEnrollments: {
            name: "UpdateEnrollments",
            I: Enrollments,
            O: Void,
            kind: MethodKind.Unary,
        },
        getSubmission: {
            name: "GetSubmission",
            I: SubmissionRequest,
            O: Submission,
            kind: MethodKind.Unary,
        },
        getSubmissions: {
            name: "GetSubmissions",
            I: SubmissionRequest,
            O: Submissions,
            kind: MethodKind.Unary,
        },
        getSubmissionsByCourse: {
            name: "GetSubmissionsByCourse",
            I: SubmissionRequest,
            O: CourseSubmissions,
            kind: MethodKind.Unary,
        },
        updateSubmission: {
            name: "UpdateSubmission",
            I: UpdateSubmissionRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        updateSubmissions: {
            name: "UpdateSubmissions",
            I: UpdateSubmissionsRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        rebuildSubmissions: {
            name: "RebuildSubmissions",
            I: RebuildRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        createBenchmark: {
            name: "CreateBenchmark",
            I: GradingBenchmark,
            O: GradingBenchmark,
            kind: MethodKind.Unary,
        },
        updateBenchmark: {
            name: "UpdateBenchmark",
            I: GradingBenchmark,
            O: Void,
            kind: MethodKind.Unary,
        },
        deleteBenchmark: {
            name: "DeleteBenchmark",
            I: GradingBenchmark,
            O: Void,
            kind: MethodKind.Unary,
        },
        createCriterion: {
            name: "CreateCriterion",
            I: GradingCriterion,
            O: GradingCriterion,
            kind: MethodKind.Unary,
        },
        updateCriterion: {
            name: "UpdateCriterion",
            I: GradingCriterion,
            O: Void,
            kind: MethodKind.Unary,
        },
        deleteCriterion: {
            name: "DeleteCriterion",
            I: GradingCriterion,
            O: Void,
            kind: MethodKind.Unary,
        },
        createReview: {
            name: "CreateReview",
            I: ReviewRequest,
            O: Review,
            kind: MethodKind.Unary,
        },
        updateReview: {
            name: "UpdateReview",
            I: ReviewRequest,
            O: Review,
            kind: MethodKind.Unary,
        },
        getOrganization: {
            name: "GetOrganization",
            I: Organization,
            O: Organization,
            kind: MethodKind.Unary,
        },
        getRepositories: {
            name: "GetRepositories",
            I: CourseRequest,
            O: Repositories,
            kind: MethodKind.Unary,
        },
        isEmptyRepo: {
            name: "IsEmptyRepo",
            I: RepositoryRequest,
            O: Void,
            kind: MethodKind.Unary,
        },
        submissionStream: {
            name: "SubmissionStream",
            I: Void,
            O: Submission,
            kind: MethodKind.ServerStreaming,
        },
    }
};
