import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { BuildInfo, Score } from "../kit/score/score_pb.js";
export class User extends Message {
    ID = protoInt64.zero;
    IsAdmin = false;
    Name = "";
    StudentID = "";
    Email = "";
    AvatarURL = "";
    Login = "";
    UpdateToken = false;
    ScmRemoteID = protoInt64.zero;
    RefreshToken = "";
    Enrollments = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.User";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "IsAdmin", kind: "scalar", T: 8 },
        { no: 3, name: "Name", kind: "scalar", T: 9 },
        { no: 4, name: "StudentID", kind: "scalar", T: 9 },
        { no: 5, name: "Email", kind: "scalar", T: 9 },
        { no: 6, name: "AvatarURL", kind: "scalar", T: 9 },
        { no: 7, name: "Login", kind: "scalar", T: 9 },
        { no: 8, name: "UpdateToken", kind: "scalar", T: 8 },
        { no: 9, name: "ScmRemoteID", kind: "scalar", T: 4 },
        { no: 10, name: "RefreshToken", kind: "scalar", T: 9 },
        { no: 11, name: "Enrollments", kind: "message", T: Enrollment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new User().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new User().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new User().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(User, a, b);
    }
}
export class Users extends Message {
    users = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Users";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "users", kind: "message", T: User, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Users().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Users().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Users().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Users, a, b);
    }
}
export class Group extends Message {
    ID = protoInt64.zero;
    name = "";
    courseID = protoInt64.zero;
    status = Group_GroupStatus.PENDING;
    users = [];
    enrollments = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Group";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "name", kind: "scalar", T: 9 },
        { no: 3, name: "courseID", kind: "scalar", T: 4 },
        { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(Group_GroupStatus) },
        { no: 6, name: "users", kind: "message", T: User, repeated: true },
        { no: 7, name: "enrollments", kind: "message", T: Enrollment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Group().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Group().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Group().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Group, a, b);
    }
}
export var Group_GroupStatus;
(function (Group_GroupStatus) {
    Group_GroupStatus[Group_GroupStatus["PENDING"] = 0] = "PENDING";
    Group_GroupStatus[Group_GroupStatus["APPROVED"] = 1] = "APPROVED";
})(Group_GroupStatus || (Group_GroupStatus = {}));
proto3.util.setEnumType(Group_GroupStatus, "qf.Group.GroupStatus", [
    { no: 0, name: "PENDING" },
    { no: 1, name: "APPROVED" },
]);
export class Groups extends Message {
    groups = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Groups";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Groups().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Groups().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Groups().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Groups, a, b);
    }
}
export class Course extends Message {
    ID = protoInt64.zero;
    courseCreatorID = protoInt64.zero;
    name = "";
    code = "";
    year = 0;
    tag = "";
    ScmOrganizationID = protoInt64.zero;
    ScmOrganizationName = "";
    slipDays = 0;
    DockerfileDigest = "";
    enrolled = Enrollment_UserStatus.NONE;
    enrollments = [];
    assignments = [];
    groups = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Course";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "courseCreatorID", kind: "scalar", T: 4 },
        { no: 3, name: "name", kind: "scalar", T: 9 },
        { no: 4, name: "code", kind: "scalar", T: 9 },
        { no: 5, name: "year", kind: "scalar", T: 13 },
        { no: 6, name: "tag", kind: "scalar", T: 9 },
        { no: 8, name: "ScmOrganizationID", kind: "scalar", T: 4 },
        { no: 9, name: "ScmOrganizationName", kind: "scalar", T: 9 },
        { no: 10, name: "slipDays", kind: "scalar", T: 13 },
        { no: 11, name: "DockerfileDigest", kind: "scalar", T: 9 },
        { no: 12, name: "enrolled", kind: "enum", T: proto3.getEnumType(Enrollment_UserStatus) },
        { no: 13, name: "enrollments", kind: "message", T: Enrollment, repeated: true },
        { no: 14, name: "assignments", kind: "message", T: Assignment, repeated: true },
        { no: 15, name: "groups", kind: "message", T: Group, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Course().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Course().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Course().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Course, a, b);
    }
}
export class Courses extends Message {
    courses = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Courses";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courses", kind: "message", T: Course, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Courses().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Courses().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Courses().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Courses, a, b);
    }
}
export class Repository extends Message {
    ID = protoInt64.zero;
    ScmOrganizationID = protoInt64.zero;
    ScmRepositoryID = protoInt64.zero;
    userID = protoInt64.zero;
    groupID = protoInt64.zero;
    HTMLURL = "";
    repoType = Repository_Type.NONE;
    issues = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Repository";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "ScmOrganizationID", kind: "scalar", T: 4 },
        { no: 3, name: "ScmRepositoryID", kind: "scalar", T: 4 },
        { no: 4, name: "userID", kind: "scalar", T: 4 },
        { no: 5, name: "groupID", kind: "scalar", T: 4 },
        { no: 6, name: "HTMLURL", kind: "scalar", T: 9 },
        { no: 7, name: "repoType", kind: "enum", T: proto3.getEnumType(Repository_Type) },
        { no: 8, name: "issues", kind: "message", T: Issue, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Repository().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Repository().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Repository().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Repository, a, b);
    }
}
export var Repository_Type;
(function (Repository_Type) {
    Repository_Type[Repository_Type["NONE"] = 0] = "NONE";
    Repository_Type[Repository_Type["INFO"] = 1] = "INFO";
    Repository_Type[Repository_Type["ASSIGNMENTS"] = 2] = "ASSIGNMENTS";
    Repository_Type[Repository_Type["TESTS"] = 3] = "TESTS";
    Repository_Type[Repository_Type["USER"] = 4] = "USER";
    Repository_Type[Repository_Type["GROUP"] = 5] = "GROUP";
})(Repository_Type || (Repository_Type = {}));
proto3.util.setEnumType(Repository_Type, "qf.Repository.Type", [
    { no: 0, name: "NONE" },
    { no: 1, name: "INFO" },
    { no: 2, name: "ASSIGNMENTS" },
    { no: 3, name: "TESTS" },
    { no: 4, name: "USER" },
    { no: 5, name: "GROUP" },
]);
export class Enrollment extends Message {
    ID = protoInt64.zero;
    courseID = protoInt64.zero;
    userID = protoInt64.zero;
    groupID = protoInt64.zero;
    user;
    course;
    group;
    status = Enrollment_UserStatus.NONE;
    state = Enrollment_DisplayState.UNSET;
    slipDaysRemaining = 0;
    lastActivityDate;
    totalApproved = protoInt64.zero;
    usedSlipDays = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Enrollment";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "courseID", kind: "scalar", T: 4 },
        { no: 3, name: "userID", kind: "scalar", T: 4 },
        { no: 4, name: "groupID", kind: "scalar", T: 4 },
        { no: 5, name: "user", kind: "message", T: User },
        { no: 6, name: "course", kind: "message", T: Course },
        { no: 7, name: "group", kind: "message", T: Group },
        { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(Enrollment_UserStatus) },
        { no: 9, name: "state", kind: "enum", T: proto3.getEnumType(Enrollment_DisplayState) },
        { no: 10, name: "slipDaysRemaining", kind: "scalar", T: 13 },
        { no: 11, name: "lastActivityDate", kind: "message", T: Timestamp },
        { no: 12, name: "totalApproved", kind: "scalar", T: 4 },
        { no: 13, name: "usedSlipDays", kind: "message", T: UsedSlipDays, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Enrollment().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Enrollment().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Enrollment().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Enrollment, a, b);
    }
}
export var Enrollment_UserStatus;
(function (Enrollment_UserStatus) {
    Enrollment_UserStatus[Enrollment_UserStatus["NONE"] = 0] = "NONE";
    Enrollment_UserStatus[Enrollment_UserStatus["PENDING"] = 1] = "PENDING";
    Enrollment_UserStatus[Enrollment_UserStatus["STUDENT"] = 2] = "STUDENT";
    Enrollment_UserStatus[Enrollment_UserStatus["TEACHER"] = 3] = "TEACHER";
})(Enrollment_UserStatus || (Enrollment_UserStatus = {}));
proto3.util.setEnumType(Enrollment_UserStatus, "qf.Enrollment.UserStatus", [
    { no: 0, name: "NONE" },
    { no: 1, name: "PENDING" },
    { no: 2, name: "STUDENT" },
    { no: 3, name: "TEACHER" },
]);
export var Enrollment_DisplayState;
(function (Enrollment_DisplayState) {
    Enrollment_DisplayState[Enrollment_DisplayState["UNSET"] = 0] = "UNSET";
    Enrollment_DisplayState[Enrollment_DisplayState["HIDDEN"] = 1] = "HIDDEN";
    Enrollment_DisplayState[Enrollment_DisplayState["VISIBLE"] = 2] = "VISIBLE";
    Enrollment_DisplayState[Enrollment_DisplayState["FAVORITE"] = 3] = "FAVORITE";
})(Enrollment_DisplayState || (Enrollment_DisplayState = {}));
proto3.util.setEnumType(Enrollment_DisplayState, "qf.Enrollment.DisplayState", [
    { no: 0, name: "UNSET" },
    { no: 1, name: "HIDDEN" },
    { no: 2, name: "VISIBLE" },
    { no: 3, name: "FAVORITE" },
]);
export class UsedSlipDays extends Message {
    ID = protoInt64.zero;
    enrollmentID = protoInt64.zero;
    assignmentID = protoInt64.zero;
    usedDays = 0;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.UsedSlipDays";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "enrollmentID", kind: "scalar", T: 4 },
        { no: 3, name: "assignmentID", kind: "scalar", T: 4 },
        { no: 4, name: "usedDays", kind: "scalar", T: 13 },
    ]);
    static fromBinary(bytes, options) {
        return new UsedSlipDays().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UsedSlipDays().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UsedSlipDays().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UsedSlipDays, a, b);
    }
}
export class Enrollments extends Message {
    enrollments = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Enrollments";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "enrollments", kind: "message", T: Enrollment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Enrollments().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Enrollments().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Enrollments().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Enrollments, a, b);
    }
}
export class Assignment extends Message {
    ID = protoInt64.zero;
    CourseID = protoInt64.zero;
    name = "";
    deadline;
    autoApprove = false;
    order = 0;
    isGroupLab = false;
    scoreLimit = 0;
    reviewers = 0;
    containerTimeout = 0;
    submissions = [];
    tasks = [];
    gradingBenchmarks = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Assignment";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "CourseID", kind: "scalar", T: 4 },
        { no: 3, name: "name", kind: "scalar", T: 9 },
        { no: 4, name: "deadline", kind: "message", T: Timestamp },
        { no: 5, name: "autoApprove", kind: "scalar", T: 8 },
        { no: 6, name: "order", kind: "scalar", T: 13 },
        { no: 7, name: "isGroupLab", kind: "scalar", T: 8 },
        { no: 8, name: "scoreLimit", kind: "scalar", T: 13 },
        { no: 9, name: "reviewers", kind: "scalar", T: 13 },
        { no: 10, name: "containerTimeout", kind: "scalar", T: 13 },
        { no: 11, name: "submissions", kind: "message", T: Submission, repeated: true },
        { no: 12, name: "tasks", kind: "message", T: Task, repeated: true },
        { no: 13, name: "gradingBenchmarks", kind: "message", T: GradingBenchmark, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Assignment().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Assignment().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Assignment().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Assignment, a, b);
    }
}
export class Task extends Message {
    ID = protoInt64.zero;
    assignmentID = protoInt64.zero;
    assignmentOrder = 0;
    title = "";
    body = "";
    name = "";
    issues = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Task";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "assignmentID", kind: "scalar", T: 4 },
        { no: 3, name: "assignmentOrder", kind: "scalar", T: 13 },
        { no: 4, name: "title", kind: "scalar", T: 9 },
        { no: 5, name: "body", kind: "scalar", T: 9 },
        { no: 6, name: "name", kind: "scalar", T: 9 },
        { no: 7, name: "issues", kind: "message", T: Issue, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Task().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Task().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Task().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Task, a, b);
    }
}
export class Issue extends Message {
    ID = protoInt64.zero;
    repositoryID = protoInt64.zero;
    taskID = protoInt64.zero;
    ScmIssueNumber = protoInt64.zero;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Issue";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "repositoryID", kind: "scalar", T: 4 },
        { no: 3, name: "taskID", kind: "scalar", T: 4 },
        { no: 4, name: "ScmIssueNumber", kind: "scalar", T: 4 },
    ]);
    static fromBinary(bytes, options) {
        return new Issue().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Issue().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Issue().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Issue, a, b);
    }
}
export class PullRequest extends Message {
    ID = protoInt64.zero;
    ScmRepositoryID = protoInt64.zero;
    taskID = protoInt64.zero;
    issueID = protoInt64.zero;
    userID = protoInt64.zero;
    ScmCommentID = protoInt64.zero;
    sourceBranch = "";
    number = protoInt64.zero;
    stage = PullRequest_Stage.NONE;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.PullRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "ScmRepositoryID", kind: "scalar", T: 4 },
        { no: 3, name: "taskID", kind: "scalar", T: 4 },
        { no: 4, name: "issueID", kind: "scalar", T: 4 },
        { no: 5, name: "userID", kind: "scalar", T: 4 },
        { no: 6, name: "ScmCommentID", kind: "scalar", T: 4 },
        { no: 7, name: "sourceBranch", kind: "scalar", T: 9 },
        { no: 8, name: "number", kind: "scalar", T: 4 },
        { no: 9, name: "stage", kind: "enum", T: proto3.getEnumType(PullRequest_Stage) },
    ]);
    static fromBinary(bytes, options) {
        return new PullRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new PullRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new PullRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(PullRequest, a, b);
    }
}
export var PullRequest_Stage;
(function (PullRequest_Stage) {
    PullRequest_Stage[PullRequest_Stage["NONE"] = 0] = "NONE";
    PullRequest_Stage[PullRequest_Stage["DRAFT"] = 1] = "DRAFT";
    PullRequest_Stage[PullRequest_Stage["REVIEW"] = 2] = "REVIEW";
    PullRequest_Stage[PullRequest_Stage["APPROVED"] = 3] = "APPROVED";
})(PullRequest_Stage || (PullRequest_Stage = {}));
proto3.util.setEnumType(PullRequest_Stage, "qf.PullRequest.Stage", [
    { no: 0, name: "NONE" },
    { no: 1, name: "DRAFT" },
    { no: 2, name: "REVIEW" },
    { no: 3, name: "APPROVED" },
]);
export class Assignments extends Message {
    assignments = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Assignments";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "assignments", kind: "message", T: Assignment, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Assignments().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Assignments().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Assignments().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Assignments, a, b);
    }
}
export class Submission extends Message {
    ID = protoInt64.zero;
    AssignmentID = protoInt64.zero;
    userID = protoInt64.zero;
    groupID = protoInt64.zero;
    score = 0;
    commitHash = "";
    released = false;
    Grades = [];
    approvedDate;
    reviews = [];
    BuildInfo;
    Scores = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Submission";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "AssignmentID", kind: "scalar", T: 4 },
        { no: 3, name: "userID", kind: "scalar", T: 4 },
        { no: 4, name: "groupID", kind: "scalar", T: 4 },
        { no: 5, name: "score", kind: "scalar", T: 13 },
        { no: 6, name: "commitHash", kind: "scalar", T: 9 },
        { no: 7, name: "released", kind: "scalar", T: 8 },
        { no: 8, name: "Grades", kind: "message", T: Grade, repeated: true },
        { no: 9, name: "approvedDate", kind: "message", T: Timestamp },
        { no: 10, name: "reviews", kind: "message", T: Review, repeated: true },
        { no: 11, name: "BuildInfo", kind: "message", T: BuildInfo },
        { no: 12, name: "Scores", kind: "message", T: Score, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Submission().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Submission().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Submission().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Submission, a, b);
    }
}
export var Submission_Status;
(function (Submission_Status) {
    Submission_Status[Submission_Status["NONE"] = 0] = "NONE";
    Submission_Status[Submission_Status["APPROVED"] = 1] = "APPROVED";
    Submission_Status[Submission_Status["REJECTED"] = 2] = "REJECTED";
    Submission_Status[Submission_Status["REVISION"] = 3] = "REVISION";
})(Submission_Status || (Submission_Status = {}));
proto3.util.setEnumType(Submission_Status, "qf.Submission.Status", [
    { no: 0, name: "NONE" },
    { no: 1, name: "APPROVED" },
    { no: 2, name: "REJECTED" },
    { no: 3, name: "REVISION" },
]);
export class Submissions extends Message {
    submissions = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Submissions";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "submissions", kind: "message", T: Submission, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Submissions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Submissions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Submissions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Submissions, a, b);
    }
}
export class Grade extends Message {
    SubmissionID = protoInt64.zero;
    UserID = protoInt64.zero;
    Status = Submission_Status.NONE;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Grade";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "SubmissionID", kind: "scalar", T: 4 },
        { no: 2, name: "UserID", kind: "scalar", T: 4 },
        { no: 3, name: "Status", kind: "enum", T: proto3.getEnumType(Submission_Status) },
    ]);
    static fromBinary(bytes, options) {
        return new Grade().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Grade().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Grade().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Grade, a, b);
    }
}
export class GradingBenchmark extends Message {
    ID = protoInt64.zero;
    CourseID = protoInt64.zero;
    AssignmentID = protoInt64.zero;
    ReviewID = protoInt64.zero;
    heading = "";
    comment = "";
    criteria = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.GradingBenchmark";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "CourseID", kind: "scalar", T: 4 },
        { no: 3, name: "AssignmentID", kind: "scalar", T: 4 },
        { no: 4, name: "ReviewID", kind: "scalar", T: 4 },
        { no: 5, name: "heading", kind: "scalar", T: 9 },
        { no: 6, name: "comment", kind: "scalar", T: 9 },
        { no: 7, name: "criteria", kind: "message", T: GradingCriterion, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new GradingBenchmark().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GradingBenchmark().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GradingBenchmark().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GradingBenchmark, a, b);
    }
}
export class Benchmarks extends Message {
    benchmarks = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Benchmarks";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "benchmarks", kind: "message", T: GradingBenchmark, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new Benchmarks().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Benchmarks().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Benchmarks().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Benchmarks, a, b);
    }
}
export class GradingCriterion extends Message {
    ID = protoInt64.zero;
    BenchmarkID = protoInt64.zero;
    CourseID = protoInt64.zero;
    points = protoInt64.zero;
    description = "";
    grade = GradingCriterion_Grade.NONE;
    comment = "";
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.GradingCriterion";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "BenchmarkID", kind: "scalar", T: 4 },
        { no: 3, name: "CourseID", kind: "scalar", T: 4 },
        { no: 4, name: "points", kind: "scalar", T: 4 },
        { no: 5, name: "description", kind: "scalar", T: 9 },
        { no: 6, name: "grade", kind: "enum", T: proto3.getEnumType(GradingCriterion_Grade) },
        { no: 7, name: "comment", kind: "scalar", T: 9 },
    ]);
    static fromBinary(bytes, options) {
        return new GradingCriterion().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GradingCriterion().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GradingCriterion().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GradingCriterion, a, b);
    }
}
export var GradingCriterion_Grade;
(function (GradingCriterion_Grade) {
    GradingCriterion_Grade[GradingCriterion_Grade["NONE"] = 0] = "NONE";
    GradingCriterion_Grade[GradingCriterion_Grade["FAILED"] = 1] = "FAILED";
    GradingCriterion_Grade[GradingCriterion_Grade["PASSED"] = 2] = "PASSED";
})(GradingCriterion_Grade || (GradingCriterion_Grade = {}));
proto3.util.setEnumType(GradingCriterion_Grade, "qf.GradingCriterion.Grade", [
    { no: 0, name: "NONE" },
    { no: 1, name: "FAILED" },
    { no: 2, name: "PASSED" },
]);
export class Review extends Message {
    ID = protoInt64.zero;
    SubmissionID = protoInt64.zero;
    ReviewerID = protoInt64.zero;
    feedback = "";
    ready = false;
    score = 0;
    gradingBenchmarks = [];
    edited;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Review";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ID", kind: "scalar", T: 4 },
        { no: 2, name: "SubmissionID", kind: "scalar", T: 4 },
        { no: 3, name: "ReviewerID", kind: "scalar", T: 4 },
        { no: 4, name: "feedback", kind: "scalar", T: 9 },
        { no: 5, name: "ready", kind: "scalar", T: 8 },
        { no: 6, name: "score", kind: "scalar", T: 13 },
        { no: 7, name: "gradingBenchmarks", kind: "message", T: GradingBenchmark, repeated: true },
        { no: 8, name: "edited", kind: "message", T: Timestamp },
    ]);
    static fromBinary(bytes, options) {
        return new Review().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Review().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Review().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Review, a, b);
    }
}
