import { makeAnyClient } from "@bufbuild/connect";
import { createAsyncIterable } from "@bufbuild/connect/protocol";
import { MethodKind, } from "@bufbuild/protobuf";
export function createResponseClient(service, transport, errorHandler) {
    return makeAnyClient(service, (method) => {
        switch (method.kind) {
            case MethodKind.Unary:
                return createUnaryFn(transport, service, method, errorHandler);
            case MethodKind.ServerStreaming:
                return createServerStreamingFn(transport, service, method);
            default:
                return null;
        }
    });
}
function createUnaryFn(transport, service, method, errorHandler) {
    return async function (input, options) {
        try {
            const response = await transport.unary(service, method, options?.signal, options?.timeoutMs, options?.headers, input);
            options?.onHeader?.(response.header);
            options?.onTrailer?.(response.trailer);
            return {
                error: null,
                message: response.message
            };
        }
        catch (error) {
            errorHandler({ method: method.name, error });
            return {
                error,
            };
        }
    };
}
export function createServerStreamingFn(transport, service, method) {
    return async function* (input, options) {
        const inputMessage = input instanceof method.I ? input : new method.I(input);
        const response = await transport.stream(service, method, options?.signal, options?.timeoutMs, options?.headers, createAsyncIterable([inputMessage]));
        options?.onHeader?.(response.header);
        yield* response.message;
        options?.onTrailer?.(response.trailer);
    };
}
