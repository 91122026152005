import { createStateHook, createActionsHook, createEffectsHook } from 'overmind-react';
import { state } from './state';
import * as actions from './actions';
import * as effects from './effects';
import * as review from './namespaces/review';
import { merge, namespaced } from 'overmind/config';
export const config = merge({
    state,
    actions,
    effects,
}, namespaced({
    review
}));
export const useAppState = createStateHook();
export const useActions = createActionsHook();
export const useGrpc = createEffectsHook();
