import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Enrollment_UserStatus, Grade, Review, Submissions } from "./types_pb.js";
export class CourseSubmissions extends Message {
    submissions = {};
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.CourseSubmissions";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "submissions", kind: "map", K: 4, V: { kind: "message", T: Submissions } },
    ]);
    static fromBinary(bytes, options) {
        return new CourseSubmissions().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CourseSubmissions().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CourseSubmissions().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CourseSubmissions, a, b);
    }
}
export class ReviewRequest extends Message {
    courseID = protoInt64.zero;
    review;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.ReviewRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4 },
        { no: 2, name: "review", kind: "message", T: Review },
    ]);
    static fromBinary(bytes, options) {
        return new ReviewRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new ReviewRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new ReviewRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(ReviewRequest, a, b);
    }
}
export class CourseRequest extends Message {
    courseID = protoInt64.zero;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.CourseRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4 },
    ]);
    static fromBinary(bytes, options) {
        return new CourseRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new CourseRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new CourseRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(CourseRequest, a, b);
    }
}
export class GroupRequest extends Message {
    courseID = protoInt64.zero;
    userID = protoInt64.zero;
    groupID = protoInt64.zero;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.GroupRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4 },
        { no: 2, name: "userID", kind: "scalar", T: 4 },
        { no: 3, name: "groupID", kind: "scalar", T: 4 },
    ]);
    static fromBinary(bytes, options) {
        return new GroupRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new GroupRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new GroupRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(GroupRequest, a, b);
    }
}
export class Organization extends Message {
    ScmOrganizationID = protoInt64.zero;
    ScmOrganizationName = "";
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Organization";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "ScmOrganizationID", kind: "scalar", T: 4 },
        { no: 2, name: "ScmOrganizationName", kind: "scalar", T: 9 },
    ]);
    static fromBinary(bytes, options) {
        return new Organization().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Organization().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Organization().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Organization, a, b);
    }
}
export class EnrollmentRequest extends Message {
    FetchMode = { case: undefined };
    statuses = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.EnrollmentRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4, oneof: "FetchMode" },
        { no: 2, name: "userID", kind: "scalar", T: 4, oneof: "FetchMode" },
        { no: 3, name: "statuses", kind: "enum", T: proto3.getEnumType(Enrollment_UserStatus), repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new EnrollmentRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new EnrollmentRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new EnrollmentRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(EnrollmentRequest, a, b);
    }
}
export class SubmissionRequest extends Message {
    CourseID = protoInt64.zero;
    AssignmentID = protoInt64.zero;
    FetchMode = { case: undefined };
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.SubmissionRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "CourseID", kind: "scalar", T: 4 },
        { no: 2, name: "AssignmentID", kind: "scalar", T: 4 },
        { no: 3, name: "UserID", kind: "scalar", T: 4, oneof: "FetchMode" },
        { no: 4, name: "GroupID", kind: "scalar", T: 4, oneof: "FetchMode" },
        { no: 5, name: "SubmissionID", kind: "scalar", T: 4, oneof: "FetchMode" },
        { no: 6, name: "Type", kind: "enum", T: proto3.getEnumType(SubmissionRequest_SubmissionType), oneof: "FetchMode" },
    ]);
    static fromBinary(bytes, options) {
        return new SubmissionRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new SubmissionRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new SubmissionRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(SubmissionRequest, a, b);
    }
}
export var SubmissionRequest_SubmissionType;
(function (SubmissionRequest_SubmissionType) {
    SubmissionRequest_SubmissionType[SubmissionRequest_SubmissionType["ALL"] = 0] = "ALL";
    SubmissionRequest_SubmissionType[SubmissionRequest_SubmissionType["USER"] = 1] = "USER";
    SubmissionRequest_SubmissionType[SubmissionRequest_SubmissionType["GROUP"] = 2] = "GROUP";
})(SubmissionRequest_SubmissionType || (SubmissionRequest_SubmissionType = {}));
proto3.util.setEnumType(SubmissionRequest_SubmissionType, "qf.SubmissionRequest.SubmissionType", [
    { no: 0, name: "ALL" },
    { no: 1, name: "USER" },
    { no: 2, name: "GROUP" },
]);
export class UpdateSubmissionRequest extends Message {
    submissionID = protoInt64.zero;
    courseID = protoInt64.zero;
    score = 0;
    released = false;
    grades = [];
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.UpdateSubmissionRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "submissionID", kind: "scalar", T: 4 },
        { no: 2, name: "courseID", kind: "scalar", T: 4 },
        { no: 3, name: "score", kind: "scalar", T: 13 },
        { no: 4, name: "released", kind: "scalar", T: 8 },
        { no: 5, name: "grades", kind: "message", T: Grade, repeated: true },
    ]);
    static fromBinary(bytes, options) {
        return new UpdateSubmissionRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateSubmissionRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateSubmissionRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateSubmissionRequest, a, b);
    }
}
export class UpdateSubmissionsRequest extends Message {
    courseID = protoInt64.zero;
    assignmentID = protoInt64.zero;
    scoreLimit = 0;
    release = false;
    approve = false;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.UpdateSubmissionsRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4 },
        { no: 2, name: "assignmentID", kind: "scalar", T: 4 },
        { no: 3, name: "scoreLimit", kind: "scalar", T: 13 },
        { no: 4, name: "release", kind: "scalar", T: 8 },
        { no: 5, name: "approve", kind: "scalar", T: 8 },
    ]);
    static fromBinary(bytes, options) {
        return new UpdateSubmissionsRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new UpdateSubmissionsRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new UpdateSubmissionsRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(UpdateSubmissionsRequest, a, b);
    }
}
export class RepositoryRequest extends Message {
    userID = protoInt64.zero;
    groupID = protoInt64.zero;
    courseID = protoInt64.zero;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.RepositoryRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "userID", kind: "scalar", T: 4 },
        { no: 2, name: "groupID", kind: "scalar", T: 4 },
        { no: 3, name: "courseID", kind: "scalar", T: 4 },
    ]);
    static fromBinary(bytes, options) {
        return new RepositoryRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RepositoryRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RepositoryRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RepositoryRequest, a, b);
    }
}
export class Repositories extends Message {
    URLs = {};
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Repositories";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "URLs", kind: "map", K: 13, V: { kind: "scalar", T: 9 } },
    ]);
    static fromBinary(bytes, options) {
        return new Repositories().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Repositories().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Repositories().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Repositories, a, b);
    }
}
export class RebuildRequest extends Message {
    courseID = protoInt64.zero;
    assignmentID = protoInt64.zero;
    submissionID = protoInt64.zero;
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.RebuildRequest";
    static fields = proto3.util.newFieldList(() => [
        { no: 1, name: "courseID", kind: "scalar", T: 4 },
        { no: 2, name: "assignmentID", kind: "scalar", T: 4 },
        { no: 3, name: "submissionID", kind: "scalar", T: 4 },
    ]);
    static fromBinary(bytes, options) {
        return new RebuildRequest().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new RebuildRequest().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new RebuildRequest().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(RebuildRequest, a, b);
    }
}
export class Void extends Message {
    constructor(data) {
        super();
        proto3.util.initPartial(data, this);
    }
    static runtime = proto3;
    static typeName = "qf.Void";
    static fields = proto3.util.newFieldList(() => []);
    static fromBinary(bytes, options) {
        return new Void().fromBinary(bytes, options);
    }
    static fromJson(jsonValue, options) {
        return new Void().fromJson(jsonValue, options);
    }
    static fromJsonString(jsonString, options) {
        return new Void().fromJsonString(jsonString, options);
    }
    static equals(a, b) {
        return proto3.util.equals(Void, a, b);
    }
}
